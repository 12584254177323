<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <slot name="icon-title" />
    <path
      fill-rule="evenodd"
      d="M15.5983 1C16.4066 1 17.0735 1.62493 17.1382 2.41841L17.1433 2.54503V4.94102H22.9489L22.9489 19.9607C22.9453 21.6236 21.588 22.9799 19.9253 22.9799H4.02101C2.35752 22.9799 1 21.6224 1 19.9589V4.94102H6.80282V2.54503C6.80282 1.73469 7.42739 1.06961 8.22118 1.00512L8.34785 1H15.5983ZM15.4515 8.81147L15.4515 6.63252H8.49436L8.49459 8.81147H6.80282V6.63252H2.69146L2.69192 19.9589C2.69192 20.6476 3.22719 21.2211 3.90092 21.2827L4.02117 21.2882H19.9255C20.6547 21.2882 21.2557 20.6876 21.2573 19.9589L21.2569 6.63252H17.1433V8.81147H15.4515ZM15.4513 2.69161L15.4514 4.94102H8.49418L8.49395 2.69161H15.4513ZM18.2567 17.1849V15.4931H5.6893V17.1849H18.2567Z"
      fill="currentColor"
    />
  </svg>
</template>
